import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import { getAllRoomTypess } from "../../RevenueManagement/RatePlans/apis/RatePlanApis";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { connect } from "react-redux";
import {
  getAllCustomers,
  getAvailableRoom,
} from "../../FlexibleSlot/api/FlexibleSlotApi";
import moment from "moment";
import { spliRoomCosts, splitRooms } from "./SplitRoomApi";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { getBookingData, invalidateNightAudit } from "../api/ReservationApi";
import { getAllNonHiddenRoomTypes } from "../../InventoryCalendar/api/InventoryApi";
import { shallowEqual, useSelector } from "react-redux";
import { handleSpringDataRequest } from "../../../api";
import { Modal } from "react-bootstrap";
import { handleSpringDataMutationRequest } from "../../../api/index";
import { useHistory } from "react-router-dom";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const SplitRoom = (props) => {
  const {
    roomID,
    closeModal,
    closeBackDrop,
    title,
    buttonText,
    load,
    reservation_id,
    bookingId,
    reload,
    reservationData,
    selectedLanguage,
  } = props;

  console.log("reservationData", reservationData);

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const classes = useStyles();
  const history = useHistory();
  //states
  const [roomTypess, setroomTypess] = useState([]);
  const [roomTypeData, setroomTypeData] = useState();
  const [roomIds, setRoomIds] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [openDirtyModal, setopenDirtyModal] = useState(false);
  const [isRoomDirty, setisRoomDirty] = useState(false);

  const openDirtysModal = () => setopenDirtyModal(true);
  const closeDirtysModal = () => setopenDirtyModal(false);

  //get all room types
  useEffect(async () => {
    const roomType = await getAllNonHiddenRoomTypes();
    setroomTypeData(roomType);
    setroomTypess(Object.keys(roomType)[0]);
  }, []);

  //handle room types  menu drop down
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const sethandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setroomTypess(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //get all avaiable room  ids
  useEffect(async () => {
    if (roomTypess.length > 0) {
      const data = await getAvailableRoom(roomTypess);
      let convertedData = data.toString();
      setRoomIds(convertedData.split(","));
      setSelectedRoomId(convertedData.split(",")[0]);
    }
  }, [roomTypess]);

  const [splitSlot, setsplitSlot] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [roomCost, setroomCost] = useState(0);
  const [loader, setloader] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);

  useEffect(() => {
    const bookingDate = new Date(reservationData.booking_checkin);
    bookingDate.setDate(bookingDate.getDate() + 1);
    setCurrentDate(bookingDate);
  }, [reservationData.booking_checkin]);

  const getAvailableSlots = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/getCheckinAndCheckoutSlots?checkin=${moment(
        new Date()
      ).format("DD-MM-YYYY[Z]HH:mm:ss")}&checkout=${moment(new Date()).format(
        "DD-MM-YYYY[Z]HH:mm:ss"
      )}`
    )
      .then((data) => {
        setAvailableSlots(data?.checkinSlots);
        setsplitSlot(
          data?.checkinSlots[Math.floor(data?.checkinSlots?.length / 2)]
        );
      })
      .catch(() => {});
  };
  useEffect(() => {
    getAvailableSlots();
  }, []);

  const handle12Hours = (hour) => {
    if (hour === 0) {
      return "12 AM";
    } else if (hour >= 1 && hour <= 11) {
      return hour + " AM";
    } else if (hour === 12) {
      return "12 PM";
    } else {
      return hour - 12 + " PM";
    }
  };

  // get split room cost
  useEffect(async () => {
    setloader(true);

    if (roomTypess.length > 0) {
      if (selectedRoomId !== undefined && selectedRoomId !== "") {
        const payload = {
          dest_room_type_id:
            roomTypess[0] === ""
              ? null
              : roomTypess.length === 1
              ? roomTypess[0]
              : roomTypess,
          checkinDate: moment(currentDate).format("ddd MMM D yyyy"),
          checkinSlot: splitSlot,
          dest_room_id: selectedRoomId,
          res_id: reservation_id,
          hotel_id: props.data.selectedHotel.hotelId,
        };
        const data = await spliRoomCosts(payload);
        setroomCost(parseInt(data?.upgrade_cost) + parseInt(data?.upgrade_tax));
        setloader(false);
      }
    }
  }, [roomTypess, splitSlot, selectedRoomId, currentDate]);

  // split room functinality
  const saveSplitRoom = async () => {
    const payload = {
      splitRoomCheckinDate: moment(currentDate).format("ddd MMM D yyyy"),
      splitRoomCheckinSlot: splitSlot,
      splitRoomID: selectedRoomId,
      splitRoomCost: roomCost,
      res_id: reservation_id,
      bookingId: bookingId,
      hotel_id: props.data.selectedHotel.hotelId,
      email: props.data.email,
    };
    setloader(true);
    const data = await splitRooms(payload);
    await invalidateNightAudit(bookingId);
    setloader(false);
    reload();
    if (data.message === "Successfully performed split room") {
      toast.success(`${t("Successfully splitted room")} ${reservation_id}`);
      closeModal();
    } else {
      toast.error(data.message);
    }

    const payloadData = {
      hotelId: props.data.selectedHotel.hotelId,
      numOfDays: 7,
      roomIdsSort: true,
      roomTypes: null,
      startDate: moment(currentDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .format("DD-MM-YYYY HH:mm:ss"),
    };
    const res = await getBookingData(payloadData, history);
    const response = await getAllNonHiddenRoomTypes();
    closeDirtysModal();
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const [demoSearch, setdemoSearch] = useState("");

  const searchFunc = async (val) => {
    setdemoSearch(val);
    const data = await getAllCustomers(val, "email");
  };

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [unblockedRooms, setUnblockedRooms] = useState();

  function handleGetUnblockedRooms() {
    handleSpringDataRequest(
      `core/api/v1/room/non-blocked-rooms-for-split/?resId=${reservation_id}&roomTypeId=${roomTypess}&startDate=${moment(
        currentDate
      ).format("DD-MM-YYYY 00:00:00")}`
    )
      .then((res) => {
        const unblocked = res.split(",");
        const set = new Set();
        unblocked.map((x) => {
          set.add(x);
        });
        setUnblockedRooms(set);
      })
      .catch((error) => console.log(`${t("Error in fetching the room ids.")}`));
  }

  useEffect(() => {
    handleGetUnblockedRooms();
  }, [roomTypess, currentDate]);

  useEffect(() => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      [reservationData?.reservation_rooms]
    )
      .then((res) => {
        const datas = Object.values(res).filter((e) => e === "DIRTY");
        if (datas?.length > 0) {
          setisRoomDirty(true);
        } else {
          setisRoomDirty(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const moveRes = (str) => {
    setloader(true);
    if (str === "YES") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-dirty`,
          {
            roomIds: [reservationData?.reservation_rooms],
            staffId: "",
            hotelId: props.data.selectedHotel.hotelId,
          }
        )
        .then((res) => saveSplitRoom())
        .catch((err) => saveSplitRoom());
    } else {
      saveSplitRoom();
    }
  };

  return (
    <div className={classes.root}>
      <CommonSlider onBackdropClick={closeBackDrop} open={true}>
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} onClick={closeModal}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <p>
              {t(
                `You are splitting Room No.(s): ${reservationData.reservation_rooms}. The reservation will be split and moved to the specified room id with the new specified checkin date.`
              )}
            </p>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <div className="d-flex flex-column">
                <Typography className="mb-1">{t("Split slot")}</Typography>
                <FormControl fullWidth disabled={loader ? true : false}>
                  <InputLabel></InputLabel>

                  <Select
                    onChange={(e) => setsplitSlot(e.target.value)}
                    label={t("Split slot")}
                    value={splitSlot}
                    notched={false}
                  >
                    {availableSlots.map((hour) => (
                      <MenuItem value={hour}>{handle12Hours(hour)}</MenuItem>
                    ))}
                  </Select>

                  {/* {hotel.slotType === "NOON" && (
                    <Select
                      onChange={(e) => setsplitSlot(e.target.value)}
                      label={t("Split slot")}
                      value={splitSlot}
                      notched={false}
                    >
                      <MenuItem value={14}>2 PM</MenuItem>
                    </Select>
                  )} */}

                  {/* {hotel.slotType === "THREE_SLOTS" && (
                    <Select
                      onChange={(e) => setsplitSlot(e.target.value)}
                      label={t("Split slot")}
                      value={splitSlot}
                      notched={false}
                    >
                      <MenuItem value={5}>5 AM</MenuItem>
                      <MenuItem value={14}>2 PM</MenuItem>
                      <MenuItem value={22}>10 PM</MenuItem>
                    </Select>
                  )} */}

                  {/* {hotel.slotType === "ALL_TIME" && (
                    <Select
                      onChange={(e) => setsplitSlot(e.target.value)}
                      label={t("Split slot")}
                      value={splitSlot}
                      notched={false}
                    >
                      <MenuItem value={0}>12 AM</MenuItem>
                      <MenuItem value={1}>1 AM</MenuItem>
                      <MenuItem value={2}>2 AM</MenuItem>
                      <MenuItem value={3}>3 AM</MenuItem>
                      <MenuItem value={4}>4 AM</MenuItem>
                      <MenuItem value={5}>5 AM</MenuItem>
                      <MenuItem value={6}>6 AM</MenuItem>
                      <MenuItem value={7}>7 AM</MenuItem>
                      <MenuItem value={8}>8 AM</MenuItem>
                      <MenuItem value={9}>9 AM</MenuItem>
                      <MenuItem value={10}>10 AM</MenuItem>
                      <MenuItem value={11}>11 AM</MenuItem>
                      <MenuItem value={12}>12 PM</MenuItem>
                      <MenuItem value={13}>1 PM</MenuItem>
                      <MenuItem value={14}>2 PM</MenuItem>
                      <MenuItem value={15}>3 PM</MenuItem>
                      <MenuItem value={16}>4 PM</MenuItem>
                      <MenuItem value={17}>5 PM</MenuItem>
                      <MenuItem value={18}>6 PM</MenuItem>
                      <MenuItem value={19}>7 PM</MenuItem>
                      <MenuItem value={20}>8 PM</MenuItem>
                      <MenuItem value={21}>9 PM</MenuItem>
                      <MenuItem value={22}>10 PM</MenuItem>
                      <MenuItem value={23}>11 PM</MenuItem>
                    </Select>
                  )} */}
                </FormControl>
                <p className="text-muted">{t("Select split checkin slot")}</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="d-flex flex-column">
                <Typography>{t("Split room cost")}</Typography>
                <TextField
                  label={t("Split room cost")}
                  notched={false}
                  className="input-lg w-100 mt-2"
                  placeholder={t("Enter the cost")}
                  onChange={(e) => setroomCost(e.target.value)}
                  value={roomCost}
                  disabled={
                    JSON.parse(
                      props?.data?.selectedHotel?.accessControl
                        ?.user_feature_map?.EditBookingPrice
                    ).read !== "YES"
                      ? true
                      : loader
                      ? true
                      : false
                  }
                />
                <p className="text-muted">
                  *{t("Automatically calculated by selections.")}
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} spacing={2}>
              <Typography className="mb-4">{t("Choose room type:")}</Typography>
              <FormControl sx={{ width: "19rem" }}>
                <InputLabel>{t("Room type")}</InputLabel>
                {roomTypeData && (
                  <Select
                    label={t("Room type")}
                    value={roomTypess}
                    onChange={sethandleChange}
                    required="true"
                    MenuProps={MenuProps}
                    disabled={loader ? true : false}
                  >
                    <MenuItem value={""}>{t("All")}</MenuItem>
                    {Object?.keys(roomTypeData)?.map((key, index) => (
                      <MenuItem
                        key={key + index}
                        value={getKeyByValue(
                          roomTypeData,
                          roomTypeData[Number(key)]
                        )}
                      >
                        {roomTypeData[Number(key)]}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <p className="text-muted">
                {`*${t(
                  "Let the current roomtype stay or select a different roomtype.Split cost field will be updated automatically."
                )}`}
              </p>
            </Grid>

            <Grid item xs={6}>
              <Typography className="mb-4">{t("New Room ID:")}</Typography>
              {roomIds?.length > 0 &&
              unblockedRooms.size > 0 &&
              !unblockedRooms?.has("") ? (
                <TextField
                  label={t("Room id")}
                  name="roomId"
                  id="roomId"
                  required
                  fullWidth
                  select
                  onChange={(e) => setSelectedRoomId(e.target.value)}
                  value={selectedRoomId}
                  disabled={loader ? true : false}
                >
                  {unblockedRooms &&
                    roomIds?.map((id) => {
                      if (unblockedRooms.has(id)) {
                        return (
                          <MenuItem key={id} value={id}>
                            {id}
                          </MenuItem>
                        );
                      }
                    })}
                </TextField>
              ) : (
                <p className="text-muted">
                  No room available in this category please select different
                  room category.
                </p>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6}>
              <div className="d-flex flex-column">
                <Typography className="mb-3">{t("Split Date:")}</Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  disabled={loader ? true : false}
                >
                  <DesktopDatePicker
                    label={t("Split Date:")}
                    // inputFormat="MMM dd, yyyy"
                    inputFormat="dd MMM yyyy"
                    value={currentDate}
                    onChange={(newValue) => {
                      setCurrentDate(newValue);
                    }}
                    // minDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <p className="text-muted">
                  {t("Select the checkin date for the newly split reservation")}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.textField}
          >
            <Fab
              type="button"
              color="primary"
              onClick={() => {
                if (
                  reservationData.booking_status.toLowerCase() ===
                  "admin_confirmed"
                ) {
                  if (isRoomDirty) {
                    saveSplitRoom();
                  } else {
                    openDirtysModal();
                  }
                } else {
                  saveSplitRoom();
                }
              }}
              variant="extended"
              disabled={loader}
            >
              {buttonText}
              {loader && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </Fab>
          </Grid>
        </Grid>
      </CommonSlider>
      <Modal
        show={openDirtyModal}
        onHide={closeDirtysModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6>{`${t("Do you want to mark room number")} - ${
            reservationData?.reservation_rooms
          } ${t("dirty")} ?`}</h6>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="custom-button"
            onClick={() => moveRes("NO")}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("NO")}`
            )}
          </Button>
          <Button
            variant="custom-button"
            onClick={() => moveRes("YES")}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("YES")}`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth };
}
export default connect(mapStateToProps)(SplitRoom);
